import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BulkSelectEmployeeDialogComponent } from '@app/components';
import {
    EmployeeCriteriaSelectionComponent,
    EmployeeSelectionCriteriaFields,
} from '@app/components/employee-criteria-selection/employee-criteria-selection.component';
import { TaskTypes } from '@app/enums';
import { EmployeeFilter } from '@app/models/employee/employee-filter.model';
import { Employee } from '@app/models/employee/employee.model';
import { TaskTemplateSubtask } from '@app/models/tasks/task-template-subtask.model';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { TitlecasePipe } from '@app/pipes';
import { AuthService } from '@app/services';
import { BaseForm } from '@forms/base.form';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-task-template-form',
    templateUrl: './task-template.template.html',
    styleUrls: ['./task-template.style.scss'],
})
export class TaskTemplateForm extends BaseForm implements OnInit {
    @Input() taskTemplate: TaskTemplate = new TaskTemplate();

    @ViewChild('employeeCriteriaSelection', { static: false })
    employeeCriteriaSelection: EmployeeCriteriaSelectionComponent;

    @ViewChild('selectEmployees', { static: true }) selectEmployees: BulkSelectEmployeeDialogComponent;

    employeeFilter: EmployeeFilter = new EmployeeFilter();

    taskTypes = TaskTypes;

    titleCasePipe = new TitlecasePipe();

    toBeCompletedBy = 'subject';
    requiredForAll = true;

    subTasks: TaskTemplateSubtask[] = [];

    assignedEmployees: Employee[] = [];

    displayBanner = false;

    constructor(
        private auth: AuthService,
        private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        /*
         * initialize subtasks by creating a copy of the template's subtasks so can
         * compare against the template when updating.
         */
        this.subTasks = [...this.taskTemplate.subtasks];

        this.setDefaultTaskType();

        if (this.taskTemplate.employeeFilter && this.taskTemplate.employeeFilter.filterCriteria) {
            /*
             * Some filter criteria's related model may have been removed from the system, so we need to
             * filter out those criteria that are no longer valid.
             */
            this.taskTemplate.employeeFilter.filterCriteria = this.taskTemplate.employeeFilter.filterCriteria.filter(
                (c) => {
                    return c.criteriable !== null && c.criteriable !== undefined;
                }
            );
        }

        // initialize requiredForAll property
        this.requiredForAll = !this.taskTemplate.employeeFilter?.filterCriteria?.length;

        // initialize employeeFilter property
        if (this.taskTemplate.employeeFilter) {
            this.employeeFilter = this.taskTemplate.employeeFilter;
        }

        // initialize assignee type & assignedEmployees property
        this.setInitialAssigneeType();

        this.initializeAssignedEmployees();
    }

    onDueDateTypeChange(): void {
        const dueDateType = this.taskTemplate.dueDateType;

        if (dueDateType === 'none') {
            this.taskTemplate.dueOffset = null;
            this.taskTemplate.dueType = null;
            this.taskTemplate.dueRelation = null;
            return;
        }

        if (dueDateType === 'custom' && this.taskTemplate.taskType === TaskTypes.general) {
            this.taskTemplate.dueRelation = 'after';
        }
    }

    getEmptyTaskTemplateSubtask(): TaskTemplateSubtask {
        return new TaskTemplateSubtask();
    }

    setSubTasks(subTasksList: TaskTemplateSubtask[]): void {
        this.subTasks = [...subTasksList];
    }

    onAddSubTasks(): void {
        this.subTasks.push(new TaskTemplateSubtask());
    }

    onRemoveSubTask(subTaskIndex: number): void {
        this.subTasks.splice(subTaskIndex, 1);
    }

    onAssigneeChange(): void {
        if (this.toBeCompletedBy === TaskTemplate.assigeeTypeSpecificEmployee) {
            this.taskTemplate.assigneeType = null;
            return;
        }

        this.taskTemplate.assigneeType = this.toBeCompletedBy;

        // reset assignees if toBeCompletedBy is not 'specific employee'
        this.attachAssignees([]);
        this.detachAssignees([]);
    }

    unassignEmployee(employeeToUnassign: Employee): void {
        this.assignedEmployees = this.assignedEmployees.filter(
            (employee: Employee) => employee.id !== employeeToUnassign.id
        );

        this.attachAssignees(this.assignedEmployees);
        this.detachAssignees(this.assignedEmployees);
    }

    onAddOrRemoveEmployees(): void {
        // set display banner value based on previous selection of employees
        this.displayBanner = this.assignedEmployees.length >= 5;

        this.selectEmployees.show<Employee[]>(this.assignedEmployees).then((selected: Employee[]) => {
            if (selected === null) {
                return;
            }

            this.assignedEmployees = selected;

            // reset display banner value based on selection of employees
            this.displayBanner = this.assignedEmployees.length >= 5;

            this.attachAssignees(this.assignedEmployees);
            this.detachAssignees(this.assignedEmployees);
        });
    }

    attachAndDetachEmployeeFilters(): Promise<void> {
        if (this.requiredForAll) {
            this.taskTemplate.employeeFilterId = null;
            return;
        }

        this.employeeFilter.name = this.getEmployeeFilterName();

        const toAttach = this.getEmployeeFiltersToAttach();
        const toDetach = this.getEmployeeFiltersToDetach();

        this.employeeFilter.attachEmployeeFilters(this.employeeFilter, toAttach);
        this.employeeFilter.detachEmployeeFilters(this.employeeFilter, toDetach);

        return this.employeeFilter
            .param('company', this.auth.company.id)
            .save()
            .then(() => {
                this.employeeCriteriaSelection.clearAttachDetachFilters();
                this.taskTemplate.employeeFilterId = this.employeeFilter.id;
            });
    }

    isAfterDueRelationDisabled(): boolean {
        return this.taskTemplate.taskType === TaskTypes.offboarding && this.isAssigneeTypeSelf();
    }

    isAssigneeTypeSelf(): boolean {
        return this.toBeCompletedBy === TaskTemplate.assigeeTypeSubject;
    }

    onTaskTypeChange(): void {
        if (this.taskTemplate.taskType === TaskTypes.general) {
            this.taskTemplate.dueRelation = null;
        }
    }

    private setDefaultTaskType(): void {
        if (this.taskTemplate.taskType) {
            return;
        }

        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.taskTemplate.taskType = params['taskType'] ?? TaskTypes.onboarding;
        });
    }

    private initializeAssignedEmployees(): void {
        this.assignedEmployees = this.taskTemplate.assignees;
    }

    private setInitialAssigneeType(): void {
        if (this.taskTemplate.assigneeType) {
            this.toBeCompletedBy = this.taskTemplate.assigneeType;
            return;
        }

        this.toBeCompletedBy = this.taskTemplate.assignees.length
            ? TaskTemplate.assigeeTypeSpecificEmployee
            : 'subject';
        this.taskTemplate.assigneeType = this.toBeCompletedBy;
    }

    private attachAssignees(selected: Employee[]): void {
        // Clear out assignees in task template before attach
        this.taskTemplate.clearAttach();

        const assigneesToAttach = selected
            .filter((selectedEmployee: Employee) => {
                return !this.taskTemplate.assignees.includes(selectedEmployee);
            })
            .map((e: Employee) => e.id);

        this.taskTemplate.attach('assignees', assigneesToAttach);
    }

    private detachAssignees(selected: Employee[]): void {
        const assigneesToDetach = this.taskTemplate.assignees
            .filter((assignee: Employee) => {
                return !selected.includes(assignee);
            })
            .map((e: Employee) => e.id);

        this.taskTemplate.detach('assignees', assigneesToDetach);
    }

    private getEmployeeFiltersToAttach(): EmployeeSelectionCriteriaFields {
        return this.requiredForAll
            ? { departments: [], offices: [], employmentTypes: [], jobs: [] }
            : this.employeeCriteriaSelection.getValuesToAttach();
    }

    private getEmployeeFiltersToDetach(): EmployeeSelectionCriteriaFields {
        return this.requiredForAll
            ? { departments: [], offices: [], employmentTypes: [], jobs: [] }
            : this.employeeCriteriaSelection.getValuesToDetach();
    }

    private getEmployeeFilterName(): string {
        if (this.taskTemplate.taskType === this.taskTypes.onboarding) {
            return 'Onboarding Task: ' + this.taskTemplate.name;
        }

        if (this.taskTemplate.taskType === this.taskTypes.offboarding) {
            return 'Offboarding Task: ' + this.taskTemplate.name;
        }

        if (this.taskTemplate.taskType === this.taskTypes.general) {
            return 'General Task: ' + this.taskTemplate.name;
        }
    }
}
